import HomeContents from "@/containers/HomeContianer/components/HomeContents";
import MainBanner from "@/containers/HomeContianer/components/MainBanner";
import { GetStaticProps } from "next";
import { system } from "../config/layout";

// export const getStaticProps: GetStaticProps = async ({ params }) => {
//   const homeMetaTag = system.defaultMetaData;

//   return {
//     props: { ...homeMetaTag },
//   };
// };

// 데이터 패칭 없어서 굳이, SSG 따로 해줄 필요없음.
// html 자동으로 생성됨.

export default function Home() {
  return (
    <>
      <MainBanner />
      <HomeContents />
    </>
  );
}
