import Tab from "@/components/Tab";
import { HStack, VStack } from "goi_common";
import { useState } from "react";
import SangjoList from "./SangjoList";
import Divider from "@/components/Divider";

type TabType = "상조 찾기" | "상조 서비스, 결합상품 비교";

const TAB_NAMES: TabType[] = ["상조 찾기", "상조 서비스, 결합상품 비교"];

const HomeContents = () => {
  const [currentTab, setCurrentTab] = useState<TabType>(TAB_NAMES[0]);

  return (
    <section>
      <VStack
        css={{
          padding: "33px 20px 20px",
        }}
        gap="12px"
      >
        <HStack gap="60px">
          <Tab
            onClick={() => {
              setCurrentTab(TAB_NAMES[0]);
            }}
            isActive={currentTab === TAB_NAMES[0]}
          >
            {TAB_NAMES[0]}
          </Tab>
          <Tab
            onClick={() => {
              setCurrentTab(TAB_NAMES[1]);
            }}
            isActive={currentTab === TAB_NAMES[1]}
          >
            {TAB_NAMES[1]}
          </Tab>
        </HStack>
        <p className="caption_12_m gray300">조문객 수별, 가격별로 맞춤형 서비스를 비교해보세요</p>
      </VStack>
      <Divider />
      {currentTab === TAB_NAMES[0] && <SangjoList />}
    </section>
  );
};

export default HomeContents;
