import { VStack } from "goi_common";

const MainBanner = () => {
  return (
    <VStack
      gap="20px"
      css={{
        height: "174px",
        justifyContent: "flex-end",
        padding: "20px",
        backgroundImage: "url('/home/main_banner.png')",
        backgroundSize: "cover",
        backgroundPosition: "center",
      }}
    >
      <VStack>
        <span className="subtitle_14_sb gray100">상조 상품 비교 사이트</span>
        <span className="subtitle_21_eb white">헤이상조</span>
      </VStack>
      <p className="caption_11_m gray300">
        상조 서비스, 가전 결합상품, 장례식장 비용까지
        <br />
        합리적이면서 믿을 수 있는 서비스를 알아보세요
      </p>
    </VStack>
  );
};

export default MainBanner;
