import { colors } from "goi_common";

import ListItem from "@/components/ListItem";
import Chip from "@/components/Chip";

import GOI_DATA from "@/constants/goi_sangjo.json";
import { SANGJO_LIST } from "@/constants/sangjo_list";
import CLOSED_SANGJO_LIST from "@/constants/closed_sangjo.json";

const SangjoList = () => {
  return (
    <ul
      css={{
        padding: "0 20px",
      }}
    >
      <ListItem
        topLabel={GOI_DATA.min_price}
        title={GOI_DATA.name}
        bottomLabel={GOI_DATA.sangjo_type}
        tags={GOI_DATA.tags}
        href={"/sangjo/goi/"}
        hasLink={GOI_DATA.hasLink}
        chip={<Chip label="추천" color={colors.blue650} />}
      />
      {SANGJO_LIST.map((item) => (
        <ListItem
          key={item.id}
          topLabel={item.min_price}
          title={item.name}
          bottomLabel={item.sangjo_type}
          hasLink={item.hasLink}
          href={`/sangjo/${item.id}/`}
          tags={item.tags}
        />
      ))}
      {CLOSED_SANGJO_LIST.map((item) => (
        <ListItem
          key={"closed" + item.id}
          topLabel="0 원부터"
          title={item.companyname}
          bottomLabel={`${item.status}상조`}
          hasLink={true}
          href={`/sangjo/closed/${item.id}/introduce/`}
          chip={<Chip label={item.status} color={colors.brown400} />}
        />
      ))}
    </ul>
  );
};

export default SangjoList;
