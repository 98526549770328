interface Props {
  label: string;
  color: string;
}

const Chip = ({ label, color }: Props) => {
  return (
    <div
      className="white"
      css={{
        padding: "2px 8px",
        backgroundColor: color,
        borderRadius: "13px",
        display: "flex",
        alignItems: "center",
        lineHeight: "normal",
        fontSize: "11px",
        fontWeight: 500,
      }}
    >
      <span>{label}</span>
    </div>
  );
};

export default Chip;
