import { ColorIcon, HStack, VStack, colors } from "goi_common";

import { ReactNode } from "react";
import { Box } from "@chakra-ui/react";

interface Props {
  topLabel: string;
  title: string;
  bottomLabel?: string;
  bottom?: ReactNode;
  href: string;
  chip?: ReactNode;
  tags?: string[];
  hasLink?: boolean;
}

const ListItem = (props: Props) => {
  const { topLabel, title, bottomLabel, bottom, href, chip, tags, hasLink } = props;
  return (
    <li
      css={{
        padding: "20px 0",
        borderBottom: `1px solid ${colors.gray800}`,
      }}
    >
      <a href={hasLink ? href : undefined}>
        <VStack
          gap="8px"
          width="100%"
          css={{
            cursor: "pointer",
          }}
        >
          <VStack gap="4px">
            <span className="gray200 caption_12_r">{topLabel}</span>
            <HStack justifyContent="space-between" alignItems="center">
              <HStack gap="8px">
                <span className="white subtitle_16_sb">{title}</span>
                {chip && chip}
              </HStack>
              {hasLink && (
                <ColorIcon name="icon-arrow-right-small-mono" size={24} color={colors.white} />
              )}
            </HStack>
            {bottomLabel && <span className="gray400 caption_12_r">{bottomLabel}</span>}
          </VStack>
          <HStack gap={6}>
            {tags?.map((tag) => (
              <Box
                key={tag}
                background={colors.green450}
                borderRadius="16px"
                padding="4px 6px"
                className="caption_12_m white"
              >
                {tag}
              </Box>
            ))}
          </HStack>
        </VStack>
        {bottom}
      </a>
    </li>
  );
};

export default ListItem;
