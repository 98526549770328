import { colors } from "goi_common";
import { ReactNode } from "react";

interface Props {
  children: ReactNode;
  onClick: () => void;
  isActive: boolean;
}

const Tab = ({ children, onClick, isActive }: Props) => {
  const className = isActive ? "white subtitle_18_b" : "gray400 body_18_m";

  return (
    <button
      className={className}
      onClick={onClick}
      css={{
        ...(isActive && {
          borderBottom: `2px solid ${colors.blue650}`,
        }),
        cursor: "pointer",
      }}
    >
      {children}
    </button>
  );
};

export default Tab;
