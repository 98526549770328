import { SangjoItemType } from "@/types/sangjoType";
// import { preedBundleProduct, preedProduct } from "./sangjo/preed";

export const SANGJO_LIST: SangjoItemType[] = [
  // {
  //   id: 2,
  //   min_price: "360만 원부터",
  //   name: "프리드라이프",
  //   sangjo_type: "선불상조",
  //   tags: ["조문객200명이상"],
  //   hasLink: true,
  //   company_description:
  //     "미리 준비하는 라이프 ‘프리드라이프’\n인생의 가장 행복한 순간을 만들어갑니다\n프리드라이프는 미리 준비하는 고품격 라이프 서비스를 통해 삶의 질을 높이고 고객의 행복을 추구합니다.\n대한민국 라이프 문화와 시장의 변화를 선도한다는 신념과 자부심으로 일합니다.",
  //   company_description_list: [
  //     "업력 23년차",
  //     "2002년 2월 21일 설립",
  //     "서울 중구 세종대로 14, 13층",
  //     "중견기업",
  //   ],
  //   product: preedProduct,
  //   bundleProduct: preedBundleProduct,
  // },
  // {
  //   id: 3,
  //   min_price: "399만 원부터",
  //   name: "대명아임레디",
  //   sangjo_type: "선불상조",
  //   tags: ["조문객200명이상"],
  //   hasLink: false,
  //   company_description: "대명라이프",
  //   company_description_list: ["대명라이프"],
  // },
  // {
  //   id: 4,
  //   min_price: "270만 원부터",
  //   name: "바로라이프",
  //   sangjo_type: "후불상조",
  //   tags: ["조문객100명이상"],
  //   hasLink: false,
  //   company_description: "바로라이프",
  //   company_description_list: ["바로라이프"],
  // },
];
